import type { AppProps } from "next/app";
import { I18nextProvider } from "react-i18next";
import { useRouter } from "next/router";

import { i18n } from "lib/i18n";

import "styles/globals.css";

export default function App({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const { locale, defaultLocale } = router;

  i18n.changeLanguage(locale || defaultLocale);

  return (
    <I18nextProvider i18n={i18n}>
      <Component {...pageProps} />
    </I18nextProvider>
  );
}
